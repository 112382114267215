import { graphql, PageProps } from "gatsby"
import React, { useContext, useEffect, useRef } from "react"
import { Layout } from "../components/atoms/Layout"
import ToyoguardDetails from "../components/organisms/Toyoguard/Details"
import OthersAlsoBought from "../components/molecules/OthersAlsoBought/OthersAlsoBought"
import { SEO } from "../components/atoms/SEO"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import tw from "twin.macro"
import { LocationContext } from "../contexts/Location"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import useDealers from "../hooks/useDealers"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import {
  Brochure,
  ImageBlocks,
  ToyotaCare,
} from "../components/organisms/Series"
import { RetentionSalesEventHero } from "../components/molecules/RetentionSalesEventHero"
import CtaBox from "../components/molecules/Heros/CtaBox"
import PartAndServiceHero from "../components/organisms/PartAndServiceHero/PartAndServiceHero"
import Coupons from "../components/molecules/Coupons/Coupons"
import Articles from "../components/organisms/Part/Articles"
import PartBanner from "../components/organisms/Part/Banner"
import PartInfo from "../components/organisms/Part/Info"
import PartDetails from "../components/organisms/Part/Details"
import PartHighlights from "../components/organisms/Part/Highlights"
import FAQ from "../components/molecules/FAQ/FAQ"
import Header from "../components/molecules/Heros/Header"
import VideoSection from "../components/molecules/VideoSection/Video"
import TabbedImageModule from "../components/molecules/TabbedImageModule/TabbedImageModule"

const ToyoguardPage: React.FC<PageProps> = ({ data, location }: any) => {
  const { page, articles, articleRoutes } = data
  //Tealium
  const { handleTealView } = useTealiumView()
  const [{ dealers }] = useContext(LocationContext)
  const [isOutOfArea] = useTealiumNoZip()
  const pageload = useRef(true)
  const { getDealerInfo } = useDealers()

  useEffect(() => {
    const dealerInfo = getDealerInfo()
    /* Fire only on inital page load, and not every time dealers is updated */

    if (isOutOfArea && pageload.current) {
      pageload.current = false
      handleTealView({
        page_name: "toyoguard",
        tealium_event: "parts_service_toyoguard",
        page_type: "parts service",
        search_results: 0,
        dealer_code: "NOT SET",
        dealer_name: "NOT SET",
      })
    }

    if (pageload.current && dealers) {
      pageload.current = false
      handleTealView({
        page_name: "toyoguard",
        tealium_event: "parts_service_toyoguard",
        page_type: "parts service",
        search_results: dealers?.length,
        dealer_code: dealerInfo.dealerCodes,
        dealer_name: dealerInfo.dealerNames,
      })
    }
  }, [dealers, isOutOfArea])

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <div css={tw`relative z-40`}>
        <h1 css={[tw`sr-only`]}>
          Toyota Toyoguard Platinum maintenance package
        </h1>
        <SanitySections
          sections={page?.content}
          title={page.title}
          articles={articles?.nodes}
          articleRoutes={articleRoutes}
          data={page}
        />
      </div>
    </Layout>
  )
}

const SanitySections = ({
  data,
  articles,
  articleRoutes,
  title,
}: any): JSX.Element => {
  const retentionSalesEventData = data?.content?.find(
    (section: any) => section._type === "salesEventHeroPageBuilder"
  )?.salesEvent
  const retentionSalesEventHeroData = retentionSalesEventData?.salesEventActive
    ? retentionSalesEventData?.hero
    : null
  return (
    <>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "header":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero
                  section={section}
                  icon={data.icon}
                  {...retentionSalesEventHeroData}
                />
              )
            }
            return (
              <Header
                section={section}
                icon={data.icon}
                aria-label="Parts & Services Hero Section"
              />
            )
          case "partsAndServiceHero":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero
                  section={section}
                  icon={data.icon}
                  {...retentionSalesEventHeroData}
                />
              )
            }
            return <PartAndServiceHero {...section} />
          case "dealersNearYouSection":
            return (
              <DealersNearYou
                type="default"
                {...section}
                ctaAnalyticsId="schedule service"
              />
            )
          case "imageBlocksWithDescriptionSection":
            return <ImageBlocksWithDescription section={section} />
          case "salesEventImageWithTextPageBuilder":
            if (!section?.salesEvent?.salesEventActive) return null
            return (
              <ToyotaCare
                {...section?.salesEvent?.imageWithText}
                imagePosition={section.imagePosition}
              />
            )
          case "brochure":
            return <Brochure {...section} />
          case "othersAlsoBoughtSection":
            return <OthersAlsoBought {...section} />
          case "toyoguardPlanDetails":
            return <ToyoguardDetails {...section} />
          case "videoSection":
            return <VideoSection {...section} />
          case "tabbedImageModule":
            return <TabbedImageModule {...section} />
          case "couponsSection":
            return <Coupons {...section} />
          case "articles":
            return (
              articles?.length > 0 && (
                <Articles
                  data={articles}
                  articleRoutes={articleRoutes}
                  {...section}
                />
              )
            )
          case "imageBlocks":
            return <ImageBlocks {...section} />
          case "iFrameUrlSection":
            return (
              <section
                aria-label="Parts iFrame Section"
                css={[tw`mx-8 my-8`, tw`md:(max-w-5xl mx-auto my-20)`]}
              >
                <iframe src={section.iFrameUrl} height="5500" width="1170" />
              </section>
            )
          case "partBannerSection":
            return <PartBanner {...section} />
          case "partDetailSection":
            return <PartDetails {...section} name={title} />
          case "partInfoSection":
            return (
              <>
                <PartInfo {...section} />
                <PartHighlights {...section} />
              </>
            )
          case "faqSection":
            return <FAQ {...section} expanded />
        }
      })}
    </>
  )
}

export const query = graphql`
  query ToyoguardPageQuery($id: String, $lang: String) {
    page: sanityPageToyoguard(id: { eq: $id }) {
      id
      language
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      content {
        ...DealersNearYouData
        ...CallToActionBoxData
        ...ImageBlocksWithDescriptionData
        ...RetentionSalesEventImageWithTextData
        ...RetentionSalesEventHeroData
        ...PartsAndServiceHeroData
        ...BrochureData
        ...ToyoguardPlanDetailsData
        ...OthersAlsoBoughtSectionData
        ...VideoSectionData
        ...CouponsSectionData
        ...TabbedImageModuleData
        ...ArticlesSectionData
        ...PartsImageBlocksData
        ...IFrameUrlSectionData
        ...PartInfoData
        ...PartDetailData
        ...FaqSectionData
        ...PartBannerData
        ...HeaderData
      }
      disclaimers {
        code
        disclaimer
      }
    }
    articles: allSanityPageArticle(
      filter: {
        language: { eq: $lang }
        categories: {
          elemMatch: { id: { eq: "-3e6d2d86-a888-57cc-8ecc-44d0f30526b7" } }
        }
      }
    ) {
      nodes {
        part {
          name
          id
        }
        categories {
          id
          category
        }
        language
        _type
        _key
        _id
        title
        featuredImage {
          ...SanityImageData
        }
      }
    }
    articleRoutes: allSanityRoute {
      nodes {
        page {
          ... on SanityPageArticle {
            _type
            _id
            #i18n_refs {
            #  ... on SanityPageArticle {
            #    _id
            #    id
            #    language
            #  }
            #}
          }
        }
        slug {
          current
        }
      }
    }
  }
`

export default ToyoguardPage
