import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { ToyoguardDetailsProps, ToyoguardService } from "./Toyoguard.d"
import { Image } from "../../atoms/Image"
import { Button } from "../../atoms/Button"
import Icon from "../../atoms/Icon"
import toyotacare from "../../../images/logos-toyotacare.svg"
import BlockContent from "../../atoms/BlockContent"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { LanguageContext } from "../../../contexts/Language"
import { parseDisclaimerBlocks } from "../../../helpers"
import {
  setDisclaimers,
  toggleDisclaimersModal,
} from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const ToyoguardDetails: React.FC<ToyoguardDetailsProps> = ({ planDetails }) => {
  const [selectedService, setSelectedService] = useState<ToyoguardService>()
  const [selectedCategory, setSelectedCategory] = useState(planDetails.faq[0])
  const { _ } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)

  useEffect(() => {
    setSelectedService(planDetails.services[0])
  }, [])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  return (
    <section
      aria-label="Toyoguard Details Section"
      css={[
        tw`relative bg-gradient-to-t from-gray-100 via-gray-100 to-gray-50`,
      ]}
    >
      <div
        css={[
          tw`grid grid-cols-2 grid-rows-1 p-4 grid-flow-row h-auto z-10 max-w-7xl w-full mx-auto px-4`,
          tw`md:(gap-28)`,
        ]}
      >
        <div css={[tw`col-span-2`, tw`md:(mt-32 col-span-1)`]}>
          <h4
            css={[
              tw`text-2xl mt-6 tracking-widest font-light leading-normal pb-8`,
            ]}
          >
            {_("Toyoguard Value")}
          </h4>
          <p css={[tw`text-xl leading-normal font-semibold`]}>
            {parseDisclaimerBlocks(planDetails.description, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </p>
        </div>
        <div
          css={[
            tw`col-start-1 row-start-1 col-span-2 flex justify-center items-center`,
            tw`md:(mt-32 col-span-1 col-start-2)`,
          ]}
        >
          {planDetails.logo && (
            <Image imageData={planDetails.logo} css={[tw`w-3/4 mt-14`]} />
          )}
        </div>

        <div css={[tw`col-span-2 px-0 w-full mt-6`, tw`md:(mx-auto px-4)`]}>
          <div
            css={[tw`overflow-auto overflow-x-scroll w-full scrollbar-hide`]}
          >
            <div css={[tw`flex flex-grow justify-between`]}>
              {planDetails.services.map((service: any, index) => {
                const TabIcon = Icon[service?.icon]
                return (
                  <button
                    css={[
                      tw`text-xs font-semibold uppercase px-8 hover:bg-gray-50 py-4 text-center z-10 w-[142px]`,
                      tw`md:(text-base w-auto)`,
                      selectedService &&
                        selectedService.name === service?.name &&
                        tw`flex-none rounded-t-xl px-4 bg-gray-50 shadow-4`,
                      tw`md:(p-0)`,
                      tw`md:(rounded-t-xl text-center py-6 px-8)`,
                    ]}
                    onClick={() => {
                      setSelectedService(service)
                      trackTealEvent({
                        tealium_event: "carousel_click",
                        carousel_action: service.name,
                      })
                    }}
                    analytics-id={`service:services carousel:${index + 1}`}
                  >
                    {TabIcon && (
                      <TabIcon
                        color="red-400"
                        css={[tw`max-h-10 mx-auto pb-2`]}
                      />
                    )}
                    {service.name}
                  </button>
                )
              })}
            </div>
          </div>

          {selectedService && (
            <>
              <div
                css={[
                  tw`relative z-20 grid grid-cols-2 grid-rows-1 grid-flow-row w-auto h-auto p-6 shadow-4 mb-20 rounded-xl bg-white`,
                  tw`md:(-top-2 gap-24 p-14 shadow-5)`,
                ]}
              >
                <div
                  css={[
                    tw`text-xl leading-normal col-span-2`,
                    tw`md:(col-span-1)`,
                  ]}
                >
                  {parseDisclaimerBlocks(
                    selectedService.description,
                    selection => dispatch(toggleDisclaimersModal(selection))
                  )}
                </div>
                <div
                  css={[
                    tw`bg-gray-100 rounded-lg -m-2 mt-8 p-2 col-span-2`,
                    tw`md:(col-span-1 p-6 -m-8)`,
                  ]}
                >
                  <h4
                    css={[
                      tw`text-2xl tracking-widest font-light leading-normal pb-8`,
                    ]}
                  >
                    {_("Extend Your Protection")}
                  </h4>
                  <div
                    css={[
                      tw`grid grid-cols-2 grid-rows-2 gap-0 grid-flow-row w-auto h-auto`,
                    ]}
                  >
                    <img src={toyotacare} css={[tw`px-6`]} />

                    <div
                      css={[
                        tw`p-4 border-l border-gray-300 text-center items-center`,
                      ]}
                    >
                      {planDetails.logo && (
                        <Image imageData={planDetails.logo} css={[tw``]} />
                      )}
                    </div>
                    <p
                      css={[
                        tw`text-center text-xl font-semibold px-2 pb-6`,
                        tw`md:(text-2xl px-10 py-2)`,
                      ]}
                    >
                      {parseDisclaimerBlocks(
                        selectedService.specToyotaCare,
                        selection => dispatch(toggleDisclaimersModal(selection))
                      )}
                    </p>
                    <p
                      css={[
                        tw`text-center text-xl font-semibold px-2 pb-6 border-l border-gray-300`,
                        tw`md:(text-2xl px-10 py-2)`,
                      ]}
                    >
                      {parseDisclaimerBlocks(
                        selectedService.specToyoGuard,
                        selection => dispatch(toggleDisclaimersModal(selection))
                      )}
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* FAQ SECTION */}
          {/* <h4
            css={[
              tw`text-2xl tracking-widest font-light leading-normal pb-8 ml-6`,
            ]}
          >
            {_("Frequently Asked Questions")}
          </h4>
          <div
            css={[
              tw`bg-white p-4 shadow-4 mb-20 rounded-xl`,
              tw`md:(shadow-5 p-14)`,
            ]}
          >
            <div
              css={tw`grid overflow-hidden grid-cols-12 gap-5 grid-flow-row w-auto h-auto`}
            >
              <div
                css={[
                  tw`col-span-12 text-left border-b border-gray-400`,
                  tw`md:(col-start-1 col-span-3 border-b-0)`,
                ]}
              >
                {planDetails.faq.map((category: any) => (
                  <div css={[tw`mb-2 inline w-auto`, tw`md:(block)`]}>
                    <Button
                      css={[
                        tw`text-black px-0 mr-4`,
                        tw`hover:text-red-400`,
                        category === selectedCategory &&
                          tw`bg-gray-50 text-red-400 pointer-events-none`,
                      ]}
                      onClick={() => {
                        setSelectedCategory(category)

                        trackTealEvent({
                          tealium_event: "accordion_open",
                          accordion_question: category.title,
                        })
                      }}
                    >
                      {category.title}
                    </Button>
                  </div>
                ))}
              </div>
              <div
                css={[
                  tw`col-span-12`,
                  tw`md:(col-span-7 col-start-4 row-start-1)`,
                ]}
              >
                {selectedCategory &&
                  selectedCategory.qa.map((qa: any) => (
                    <div css={[tw`mb-4`]}>
                      <span css={tw`font-semibold uppercase block`}>
                        {qa.question}
                      </span>
                      {qa.answer && <BlockContent data={qa.answer} />}
                    </div>
                  ))}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default ToyoguardDetails
