import React, { useContext } from "react"
import tw from "twin.macro"
import type { OthersAlsoBoughtProps } from "./OthersAlsoBought.d"
import { Image } from "../../atoms/Image"
import { Link } from "../../atoms/Link"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const OthersAlsoBought: React.FC<OthersAlsoBoughtProps> = ({
  othersAlsoBought,
}) => {
  const { _ } = useContext(LanguageContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  return (
    <section aria-label="Others Also Bought Section">
      <h2
        css={[
          tw`text-left pl-8 block text-2xl font-light tracking-widest mt-6`,
          tw`md:(w-full text-3xl text-center pl-0 my-12)`,
        ]}
      >
        {_("You May Also Be Interested In")}
      </h2>
      <div css={[tw`flex flex-col`, tw`md:(flex-row)`]}>
        {othersAlsoBought?.map((block, index) => (
          <div
            css={[
              othersAlsoBought.length === 1 && tw`w-full md:(w-full)`,
              othersAlsoBought.length === 2 && tw`w-full md:(w-1/2)`,
              othersAlsoBought.length === 3 && tw`w-full md:(w-1/3)`,
              othersAlsoBought.length === 4 && tw`w-full md:(w-1/4)`,
            ]}
            key={index}
          >
            {block.imageLink.linkType === "internal" ? (
              <Link
                to={`/${block.imageLink?.internalLink?.slug?.current}`}
                css={[tw`inline`]}
                onClick={() => {
                  trackTealEvent({
                    page_name: "toyoguard",
                    tealium_event: "footer_module",
                    link_href: `/${block.imageLink?.internalLink?.slug?.current}`,
                    coupon_module_text: `${block?.heading}`,
                  })
                }}
                analytics-id={`related pages:footer:${index + 1}`}
              >
                <div css={[tw`flex pl-8 md:(justify-center items-center)`]}>
                  {block?.heading && (
                    <div css={[tw`text-lg font-semibold text-black py-5`]}>
                      {block?.heading}
                    </div>
                  )}
                </div>
                <Image
                  imageData={block?.image?.image}
                  css={[tw`h-56 w-full object-cover`, tw`md:(h-72 mb-0)`]}
                />
              </Link>
            ) : (
              <Link
                to={block.imageLink?.externalUrl}
                target={`_blank`}
                css={[tw`inline`]}
              >
                <div css={[tw`flex pl-8 md:(justify-center items-center)`]}>
                  {block?.heading && (
                    <div css={[tw`text-lg font-semibold text-black py-5`]}>
                      {block?.heading}
                    </div>
                  )}
                </div>
                <Image
                  imageData={block?.image?.image}
                  css={[tw`h-56 w-full object-cover`, tw`md:(h-72 mb-0)`]}
                />
              </Link>
            )}
          </div>
        ))}
      </div>
    </section>
  )
}

export default OthersAlsoBought
